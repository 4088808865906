<template>
  <div class="background">
    <NotifyPayment />
  </div>
</template>

<script>
import NotifyPayment from "@/components/elements/dashboard/payment/notify-payment/NotifyPayment";

export default {
  name: "DocumentManagementNotifyPayment",

  components: {
    NotifyPayment,
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
//
</style>
